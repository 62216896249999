$(function () {
	//Confirmation Popup
	$("body").on("click", 'a[class^="ewk-"], .extwarn', function (e) {
		e.preventDefault();
		var containerId = "defaultsiteoverlay";
		var keyValue = "";
		var classes = $(this).attr("class").split(" ");
		for (var i = 0; i < classes.length; i++) {
			var matches = /^ewk\-(.+)/.exec(classes[i]);
			if (matches != null) {
				keyValue = matches[1];
			}
		}

		$.ajax({
			url: "/CFACOM/PageContent/ConfirmationOverlay",
			type: "GET",
			data: {
				OverlayKey: keyValue,
				UrlRedirectValue: $(this).prop("href")
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);

			$.magnificPopup.open({
				items: {
					src: "#" + containerId,
					type: "inline"
				}
			});
		});
	});
});

$("body").on("click", ".extwarnjobopportunity", function (e) {
	e.preventDefault();
	var containerId = "#jobopportunitiesoverlay";
	var url = $(this).prop("href");

	$(containerId).find("a.continue-overlay").prop("href", url);

	$.magnificPopup.open({
		items: {
			src: containerId,
			type: "inline"
		}
	});
});

function validateForm() {
	var valid = true;

	$("#Address").next(".field-validation-error").remove();
	if (!$("#Address").val()) {
		$("#Address").after(
			'<span class="field-validation-error" data-valmsg-for="Address" data-valmsg-replace="true">You must provide an address</span>'
		);
		valid = false;
	}

	$("#City").next(".field-validation-error").remove();
	if (!$("#City").val()) {
		$("#City").after(
			'<span class="field-validation-error" data-valmsg-for="City" data-valmsg-replace="true">You must provide a city</span>'
		);
		valid = false;
	}

	$("#State").parent().next(".field-validation-error").remove();
	if (!$("#State").val()) {
		$("#State")
			.parent()
			.after(
				'<span class="field-validation-error" data-valmsg-for="State" data-valmsg-replace="true">You must select a state</span>'
			);
		valid = false;
	}

	var isValid = /(^\d{5}(-\d{4})?$)/.test($("#Zip").val());
	$("#Zip").next(".field-validation-error").remove();
	if (!isValid) {
		$("#Zip").after(
			'<span class="field-validation-error" data-valmsg-for="Zip" data-valmsg-replace="true">You must provide a zip code</span>'
		);
		valid = false;
	}

	return valid;
}

function validatePickupAddress() {
	var valid = true;

	$("#address").next(".field-validation-error").remove();
	if (!$("#address").val()) {
		$("#address").after(
			'<span class="field-validation-error" data-valmsg-for="address" data-valmsg-replace="true">You must provide an address</span>'
		);
		valid = false;
	}

	return valid;
}
